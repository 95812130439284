exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-articles-personalization-tsx": () => import("./../../../src/pages/articles/personalization.tsx" /* webpackChunkName: "component---src-pages-articles-personalization-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-narratives-tsx": () => import("./../../../src/pages/narratives.tsx" /* webpackChunkName: "component---src-pages-narratives-tsx" */),
  "component---src-pages-persona-charts-tsx": () => import("./../../../src/pages/persona/charts.tsx" /* webpackChunkName: "component---src-pages-persona-charts-tsx" */),
  "component---src-pages-removed-videos-tsx": () => import("./../../../src/pages/removedVideos.tsx" /* webpackChunkName: "component---src-pages-removed-videos-tsx" */),
  "component---src-pages-top-videos-tsx": () => import("./../../../src/pages/topVideos.tsx" /* webpackChunkName: "component---src-pages-top-videos-tsx" */)
}

